import { Text } from '@cinch-labs/design-system'
import { getDayMonthYearFormat } from '@cinch-labs/shared-util'
import { TrackingEventTypes, useAnalytics } from '@cinch-nx/shared-analytics'
import cx from 'classnames'

import styles from './trustpilot-review-card.module.css'

export interface ReviewCardProps {
  starRating: number
  title: string
  description: string
  customerName: string
  date: string
  link: string
}

const ReviewCard: React.FC<ReviewCardProps> = ({
  starRating,
  title,
  description,
  customerName,
  date,
  link,
}) => {
  const { trackEvent } = useAnalytics()
  const trackClick = () => {
    trackEvent({
      eventName: 'trustpilot review',
      type: TrackingEventTypes.ADOBE,
      data: {
        event: {
          name: 'click',
          category: 'button',
          label: `trustpilot review cards`,
          action: `click on review card`,
          value: 'full review',
        },
      },
    })
  }

  return (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      data-testid="review-card"
      className={styles.trustpilotReviewLink}
      onClick={trackClick}
    >
      <div className={styles.reviewCardContainer}>
        <div>
          <div
            className={styles.starRatingContainer}
            role="figure"
            aria-label={`${starRating} of 5 rating`}
          >
            {[...Array.from({ length: 5 })].map((_, i) => (
              <span
                className={cx(styles.starRatingIconContainer, {
                  [styles.starRatingIconContainerFilled]: i + 1 <= starRating, // Count of filled stars,
                })}
                key={i}
              >
                <svg
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={styles.starRatingIcon}
                >
                  <path
                    d="m11.469 15.893 4.705-1.195 1.966 6.07-6.671-4.875Zm10.828-7.847h-8.282L11.469.23 8.923 8.046H.64l6.703 4.844-2.546 7.814L11.5 15.86l4.125-2.97 6.67-4.844Z"
                    fill="#fff"
                  />
                </svg>
              </span>
            ))}
          </div>
          <Text
            fontSize="xl"
            fontWeight="bold"
            className={styles.trustPilotTitle}
          >
            {title}
          </Text>
          <Text fontWeight="medium" className={styles.trustPilotDescription}>
            {description}
          </Text>
        </div>
        <div>
          <Text fontWeight="bold" className={styles.trustPilotCustomerName}>
            {customerName}
          </Text>
          <Text
            fontSize="m"
            fontWeight="medium"
            className={styles.trustPilotDate}
          >
            {getDayMonthYearFormat(date)}
          </Text>
        </div>
      </div>
    </a>
  )
}

export default ReviewCard
