import { Modal, Secondary, Text } from '@cinch-labs/design-system'
import {
  buildTagDataAttr,
  sendDigitalDataEvent,
} from '@cinch-nx/shared-analytics'
import debounce from 'lodash/debounce'
import React, { useState } from 'react'

import styles from './modal-chat.module.css'

export const whatsAppHref = 'https://wa.me/447585658040'
export const smsHref = 'sms:+442033224567'
export const sendChatModalOpenEvent = debounce(() => {
  const data = {
    event: {
      name: 'click',
      category: 'chatModal',
      label: 'Chat Modal',
      action: 'open',
    },
  }

  sendDigitalDataEvent(data)
}, 100)

export const ModalChat: React.FC = () => {
  const [showModal, setShowModal] = useState(false)
  const whatsAppButtonText = 'WhatsApp'
  const smsButtonText = 'Text message'

  const handleOpenModal = () => {
    sendChatModalOpenEvent()
    setShowModal(true)
  }

  return (
    <div className={styles['container']}>
      <button
        className={styles['button']}
        onClick={handleOpenModal}
        data-testid="chat"
      >
        {'Chat'}
      </button>

      {showModal && (
        <Modal
          titleId="live-chat-title"
          bodyId="live-chat-body"
          title={'Live chat support'}
          onClose={() => setShowModal(false)}
        >
          <Text
            className={styles.text}
            element="p"
            fontWeight="book"
            fontSize="m"
            lineHeight="display"
          >
            How would you like to chat?
          </Text>

          <div className={styles.buttonContainer}>
            <Secondary
              isFullWidth={true}
              {...buildTagDataAttr({
                event: 'click',
                eventCategory: 'whatsApp',
                eventLabel: whatsAppButtonText,
                eventAction: 'open',
              })}
              element="a"
              href={whatsAppHref}
            >
              <span>{whatsAppButtonText}</span>
            </Secondary>

            <Secondary
              isFullWidth={true}
              {...buildTagDataAttr({
                event: 'click',
                eventCategory: 'sms',
                eventLabel: smsButtonText,
                eventAction: 'open',
              })}
              element="a"
              href={smsHref}
            >
              <span>{smsButtonText}</span>
            </Secondary>
          </div>
        </Modal>
      )}
    </div>
  )
}
