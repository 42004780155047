import type { HowToProps, SEO } from '@cinch-nx/data-storyblok'
import Head from 'next/head'
import { useRouter } from 'next/router'

export const withTitleSuffix = (title: string) =>
  title.includes('- cinch') ? title : `${title} - cinch`

export interface HeadProps {
  fallbackTitle: string
  fallbackDescription: string
  title?: SEO['title']
  description?: SEO['description']
  canonicalUrl?: SEO['canonicalUrl']
  robots?: SEO['robots'] | []
  openGraph?: SEO['openGraph'] | []
  twitterCard?: SEO['twitterCard'] | []
  generatedAt?: number
  HowTo?: HowToProps[]
}

const LandingHead: React.FC<HeadProps> = ({
  title,
  fallbackTitle,
  description,
  fallbackDescription,
  canonicalUrl,
  robots,
  openGraph,
  twitterCard,
  generatedAt,
  HowTo,
}) => {
  const router = useRouter()
  const ogData = openGraph?.[0]
  const twitterCardData = twitterCard?.[0]
  const pathname = router?.asPath ?? ''
  canonicalUrl = !canonicalUrl
    ? `https://www.cinch.co.uk${pathname.split('?')[0]}`
    : canonicalUrl

  return (
    <Head>
      {/* A title tag is an HTML element that specifies the title of a web page. */}
      <title>{withTitleSuffix(title || fallbackTitle)}</title>
      {/* A meta description is an HTML element that provides a brief summary of a web page. */}
      <meta name="description" content={description || fallbackDescription} />
      {/* The robots meta tag lets you utilize a granular, page-specific approach to controlling how an individual page should be indexed and served to users in search results.  */}
      <meta name="robots" content={robots?.join(', ') || 'all'} key="robots" />
      {/* `generatedAt` shows the timestamp when the page was last statically built on the server */}
      {generatedAt && (
        <meta name="page_generated_at" content={`${generatedAt}`} />
      )}
      <link
        rel="icon"
        href="/favicon-32x32-bfc05e34c2d7531ace62bdb1cc158dfe.png"
      />
      {(ogData?.title || ogData?.type) && (
        <meta
          property="og:url"
          content={`https://www.cinch.co.uk${pathname}`}
        />
      )}
      {ogData?.type && <meta property="og:type" content={ogData.type} />}
      {ogData?.title && <meta property="og:title" content={ogData.title} />}
      {ogData?.description && (
        <meta property="og:description" content={ogData.description} />
      )}
      {ogData?.imageUrl && (
        <meta property="og:image" content={ogData.imageUrl} />
      )}
      {ogData?.imageAlt && (
        <meta property="og:image:alt" content={ogData.imageAlt} />
      )}
      {/* Twitter Card - https://developer.twitter.com/en/docs/twitter-for-websites/cards/overview/abouts-cards */}
      {twitterCardData?.card && (
        <meta name="twitter:card" content={twitterCardData.card} />
      )}
      {twitterCardData?.card && <meta name="twitter:site" content="@cinchuk" />}
      {twitterCardData?.card && (
        <meta property="twitter:domain" content="cinch.co.uk" />
      )}
      {twitterCardData?.card && (
        <meta
          property="twitter:url"
          content={`https://www.cinch.co.uk${pathname}`}
        />
      )}
      {twitterCardData?.title && (
        <meta name="twitter:title" content={twitterCardData?.title} />
      )}
      {twitterCardData?.description && (
        <meta
          name="twitter:description"
          content={twitterCardData?.description}
        />
      )}
      {twitterCardData?.imageUrl && (
        <meta name="twitter:image" content={twitterCardData?.imageUrl} />
      )}
      {twitterCardData?.imageAlt && (
        <meta name="twitter:image:alt" content={twitterCardData?.imageAlt} />
      )}
      {process.env['STAGE_NAME'] === 'production' && (
        <>
          {/*
          A canonical tag (aka "rel canonical") is a way of telling search engines that a specific URL represents the main copy of a page.
          https://moz.com/learn/seo/canonicalization
        */}
          <link rel="canonical" href={canonicalUrl} />

          <link rel="sitemap" type="application/xml" href="/sitemap.xml"></link>

          <link
            rel="manifest"
            href="/manifest.webmanifest"
            crossOrigin="anonymous"
          ></link>
        </>
      )}
    </Head>
  )
}

export default LandingHead
